.link {
    display: inline-block;
    cursor: pointer;
    &.disabled {
        cursor: not-allowed;
    }
    a {
        cursor: inherit;
    }
    .inner {
        text-decoration: none;
        color: inherit;
    }
}
