.clients {
    .spinner {
        text-align: center;
        padding: 16px 0;
    }
    .table {
        .codePhone {
            color: var(--text_secondary);
            padding-right: 2px;
        }
        .link {
            text-decoration: underline;
        }
    }
}
