.textField {
    display: flex;
    box-sizing: border-box;
    background-color: var(--background_primary);
    font-size: 14px;
    height: var(--input_height);
    align-items: center;
    border: none;
    border-radius: 4px;
    border: 1px solid transparent;
    transition: var(--base_transition);
    &:hover {
        border: 1px solid var(--border);
    }
    &:focus-within {
        box-shadow: var(--focused_shadow);
    }
    &.disabled {
        background-color: transparent;
        border: 1px solid var(--border);
        cursor: auto;
        input {
            color: var(--text_quarternary);
            background-color: transparent;
        }
        svg {
            fill: var(--text_quarternary);
        }
        .actions {
            .aIcon {
                &:hover {
                    background-color: transparent;
                    cursor: auto;
                }
            }
        }
    }
    input {
        flex: 1 1 100%;
        color: var(--text_primary);
        background-color: var(--background_primary);
        border: none;
        font-family: var(--primary_font);
        outline: none;
        margin: 0 8px;
        transition: var(--base_transition);
        width: 100%;
        font-size: 14px;
        &::placeholder {
            color: var(--text_tertiary);
        }
    }
    .icon {
        margin-left: 8px;
    }
    .actions {
        flex: 1 0 auto;
        cursor: pointer;
        .aIcon {
            transition: var(--base_transition);
            padding: 0 3px;
            line-height: var(--input_height);
            &:hover {
                background-color: var(--hover);
            }
            svg {
                vertical-align: middle;
            }
        }
    }
}
