.log {
    position: absolute;
    top: 24px;
    z-index: 1010;
    left: 600px;
    background: var(--background_primary);
    color: var(--text_primary);
    width: 600px;
    padding-bottom: 16px;
    h2 {
        padding: 16px;
        margin: 0;
    }
    ul {
        overflow: auto;
        li {
            padding: 8px 16px;
            &:hover {
                background-color: var(--hover);
            }
        }
    }
}