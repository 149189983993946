.button {
    display: inline-block;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    border-radius: 4px;
    border: 2px solid transparent;
    color: var(--text_inverted);
    font-family: var(--primary_font);
    outline: none;
    padding: 11px 16px;
    &:focus, &:focus-within {
        box-shadow: var(--focused_shadow);
    }
    &.primary {
        background-color: var(--primary);
        &:hover,
        &:focus {
            background-color: var(--hover_primary);
        }
    }
    &.secondary {
        color: var(--primary);
        background-color: transparent;
        border: 2px solid var(--border);
        &:hover,
        &:focus {
            background-color: var(--hover_secondary);
            border-color: var(--primary);
        }
    }
    &.success {
        background-color: var(--success);
        &:hover,
        &:focus {
            background-color: var(--hover_success);
        }
    }
    &.error {
        background-color: var(--error);
        &:hover,
        &:focus {
            background-color: var(--hover_error);
        }
    }
    &.disabled,
    &[disabled] {
        opacity: 0.2;
    }
    & > a {
        outline: none;
    }
}
