.menu {
    background-color: var(--background_popup);
    text-shadow: var(--popup_shadow);
    border-radius: 6px;
    padding: 8px 0;
    color: var(--text_secondary);
    white-space: nowrap;
    &.show-check {
        .check {
            width: 24px;
        }
    }
    .item {
        display: flex;
        align-items: center;
        height: 32px;
        padding: 0 8px;
        cursor: pointer;
        .text {
            padding: 0 8px;
            flex: 1 1 100%;
        }
        .icon {
            padding-right: 8px;
        }
        .check {
            svg {
                fill: var(--primary);
            }
        }
        &:hover {
            background-color: var(--hover);
            color: var(--text_primary);
        }
    }
}
