.checkbox {
    white-space: nowrap;
    cursor: pointer;
    & > * {
        display: inline-block;
        vertical-align: middle;
    }
    &.disabled {
        opacity: 0.3;
        cursor: not-allowed !important;
    }
    svg {
        fill: var(--text_tertiary);
        margin-right: 8px;
    }
    &.checked {
        svg {
            fill: var(--primary);
        }    
    }
    &:focus {
        text-shadow: 1px 1px 2px var(--border);
    }
}