.dialog {
    background-color: var(--background_popup);
    padding: 16px 0;
    text-align: left;
    border-radius: 8px;
    color: var(--text_primary);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2000;
    h2 {
        font-size: 20px;
        font-weight: 700;
        margin: 0;
        padding: 0 24px 16px;
    }
    .content {
        padding: 0 24px;
        min-width: 280px;
    }
    .actions {
        display: flex;
        padding: 36px 16px 0;
        .spacer {
            flex: 1 1 100%;
            text-align: center;
        }
        button {
            flex: 0 0 auto;
            cursor: pointer;
            &:not(:last-child) {
                margin-right: 8px;
            }
        }
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: var(--background_modal);
}
