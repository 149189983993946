@import "./uikit/themes.scss";
@import "leaflet/dist/leaflet.css";

@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(./uikit/fonts/Rubik-Regular.ttf);
}

* {
  outline: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Rubik", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  min-height: 100%;
  width: 100%;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

ul,
p {
  margin: 0;
  padding: 0;
  list-style: none;
}

.primary-font {
  font-family: var(--primary_font);
}
.popup_shadow {
  z-index: 1100;
}
