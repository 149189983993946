.page404 {
    text-align: center;
    width: 50%;
    margin: auto;
    color: var(--text_primary);
    h2 {
        font-size: 108px;
        margin: 48px;
        .zero {
            color: gray;
            color: var(--text_secondary);
        }
    }
    h3 {
        font-size: 48px;
        margin-bottom: 32px;
    }
}
