.panel {
    background: var(--background_primary);
    h4 {
        margin: 0;
    }
    .panelTitle {
        padding: 8px;
        background-color: var(--hover_secondary);
        cursor: pointer;
        .icon {
            margin-right: 8px;
        }
        .down {
            float: right;
            transition: all 0.2s ease;
            border-radius: 50%;
            &:hover {
                background-color: var(--background_active);
            }
        }
    }
    .panelText {
        max-height: 0px;
        overflow: hidden;
        transition: all 0.2s ease;
    }
    &.opened {
        .down {
            transform: rotate(180deg);
        }
    }
}
.row {
    display: flex;
    padding: 4px 8px;
    border-bottom: 1px solid var(--border);
    label {
        flex-basis: 280px;
        flex: 1 1 100%;
    }
    .value {
        flex: 1 1 100%;
    }
}
