.icon {
    display: inline-block;
    &.button {
        line-height: 32px;
        width: 32px;
        text-align: center;
        border-radius: 4px;
    }
    svg {
        vertical-align: middle;
        fill: var(--text_secondary);
    }
    &.button:hover {
        background: var(--hover);
    }
}
