.home {
    display: flex;
    min-height: 100%;
    min-height: 100vh;
    .pages {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .routes {
        flex: 1 1 100%;
    }
}

.toolbar {
    height: 80px;
    display: flex;
    align-items: center;
    padding-right: 30px;
    background-color: var(--background_primary);
    white-space: nowrap;
    user-select: none;
    .tabs {
        flex: 1 1 100%;
        font-weight: bold;
        font-size: 20px;
        color: var(--text_tertiary);
        li {
            display: inline-block;
            border-bottom: 3px solid transparent;
            line-height: 80px;
            height: 80px;
            box-sizing: border-box;
            cursor: pointer;
            transition: all 0.5s ease-in-out;
            .block {
                padding: 0 24px;
                border-right: 1px solid var(--border);
                .title {
                    padding-right: 8px;
                }
            }
            &.active {
                border-bottom-color: var(--primary);
                color: var(--text_primary);
            }
        }
    }
    .actions {
        flex: 0 0 auto;
        white-space: nowrap;
        li {
            display: inline-block;
            padding: 0 4px;
            cursor: pointer;
            white-space: nowrap;
            svg {
                fill: var(--text_secondary);
            }
        }
    }
    svg {
        fill: var(--text_tertiary);
    }
}

.sideBar {
    background-color: var(--background_tertiary);
    color: var(--text_navbar);
    font-size: 12px;
    display: flex;
    flex-direction: column;
    .expander {
        flex: 1 1 100%;
    }
    .menuItem {
        display: block;
        width: 80px;
        line-height: 80px;
        text-align: center;
        user-select: none;
        border-left: 3px solid;
        border-right: 3px solid;
        border-color: transparent;
        color: var(--text_navbar);
        opacity: 0.2;
        &.isVisible {
            opacity: 1;
        }
        &.pointer {
            cursor: pointer;
        }
        .itemContent {
            display: inline-block;
            line-height: normal;
            vertical-align: middle;
            svg {
                fill: var(--text_navbar);
            }
        }
        &:hover {
            background-color: var(--background_secondary);
        }
        &.active {
            background-color: var(--background_secondary);
            border-left-color: var(--primary);
            color: #fff;
            svg {
                fill: #fff;
            }
        }
    }
}

.routes {
    background-color: var(--background_secondary);
}

:global .theme-light {
    :local .sideBar .menuItem {
        &.active,
        &:hover {
            background-color: #39434c;
        }
    }
}
:global .theme-dark {
    :local .sideBar .menuItem {
        &.active,
        &:hover {
            background-color: #1e2021;
        }
    }
}
.lang {
    color: var(--text_primary);
    text-transform: uppercase;
    cursor: pointer;
    padding-left: 1rem;
    line-height: 64px;
}

.userMenu {
    background: var(--background_primary);
    color: var(--text_primary);
    padding: 16px;
    text-align: center;
    .email {
        padding: 4px 0 16px;
        font-size: 0.75rem;
    }
    button {
        width: 100%;
        cursor: pointer;
    }
}