.detail {
    background-color: var(--background_secondary);
    color: var(--text_primary);
    display: flex;
    .left {
        width: 480px;
    }
    .right {
        flex: 1 1 100%;
    }
    .panel {
        margin: 12px 8px;
    }
    .checkbox {
        display: inline-block;
        padding: 0 4px;
    }
}
.notificatons {
    svg {
        padding: 4px;
    }
}
