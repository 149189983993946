.rating {
    .value {
        font-weight: bold;
        font-size: 14px;
        padding-right: 8px;
    }
    svg {
        fill: var(--primary);
        opacity: 0.2;
        vertical-align: middle;
        &.filled {
            opacity: 1;
        }
    }
}
