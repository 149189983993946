:root {
  --primary_font: "Rubik", sans-serif;
  --input_height: 32px;
  --base_transition: all 0.2s ease;
  --stop: #e55959;
}
.theme-light {
  --primary: #0175d8;
  --background_active: #d9edff;

  --background_primary: #ffffff;
  --background_secondary: #edeff0;
  --background_tertiary: #262d33;
  --background_popup: #ffffff;
  --background_modal: rgba(0, 0, 0, 0.2);

  --text_primary: #262d33;
  --text_secondary: #4c565f;
  --text_tertiary: #969fa7;
  --text_quarternary: #c6ced4;
  --text_inverted: #ffffff;
  --text_navbar: #959ea6;

  --border: #c7ced4;
  --hover: rgba(41, 74, 103, 0.07);
  --shadow: rgba(20, 37, 51, 0.15);

  --error: #d50000;
  --success: #00a637;
  --stop: #e55959;

  --hover_primary: #0168bf;
  --hover_error: #ba0000;
  --hover_success: #008c2f;
  --hover_secondary: rgba(1, 117, 216, 0.2);

  --focused_shadow: 0px 0px 0px 3px rgba(1, 117, 216, 0.3);
  .popup_shadow {
    box-shadow: 0px 20px 32px rgba(20, 37, 51, 0.15), 0px 8px 16px rgba(20, 37, 51, 0.15),
      0px 0px 6px rgba(20, 37, 51, 0.15);
  }
  .map_button_shadow {
    background: #ffffff;
    background-blend-mode: multiply;
    box-shadow: 0px 4px 12px rgba(20, 37, 51, 0.15);
  }

  --order_status_created: #42a3f5;
  --order_status_accepted: #8200e9;
  --order_status_arrived: #ff9900;
}

.theme-dark {
  --primary: #55aaf2;
  --background_active: #13324d;

  --background_primary: #1e2021;
  --background_secondary: #2e3133;
  --background_tertiary: #0b0c0d;
  --background_popup: #2e3133;
  --background_modal: rgba(255, 255, 255, 0.2);

  --text_primary: #ffffff;
  --text_secondary: #aeb7bf;
  --text_tertiary: #676d73;
  --text_quarternary: #4c5054;
  --text_inverted: #0f1112;
  --text_navbar: #737a80;

  --border: #45494d;
  --hover: rgba(255, 255, 255, 0.07);
  --shadow: rgba(0, 0, 0, 0.25);

  --error: #e85d5d;
  --success: #47d376;
  --stop: #e55959;

  --hover_primary: #2f96ed;
  --hover_error: #d64949;
  --hover_success: #30bf60;
  --hover_secondary: rgba(85, 170, 242, 0.3);

  --focused_shadow: 0px 0px 0px 3px rgba(85, 170, 242, 0.3);
  .popup_shadow {
    box-shadow: 0px 20px 32px rgba(0, 0, 0, 0.25), 0px 8px 16px rgba(0, 0, 0, 0.25), 0px 0px 6px rgba(0, 0, 0, 0.25);
  }
  .map_button_shadow {
    background: #ffffff;
    background-blend-mode: multiply;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  }

  --order_status_created: #6eb8f8;
  --order_status_accepted: #aa55ed;
  --order_status_arrived: #ffa133;
}
