.back {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--background_secondary);
    color: var(--text_secondary);
    .login {
        width: 320px;
        margin: 4em auto 0;
        border-radius: 8px;
        border: 1px solid var(--border);
        padding: 16px 16px;
        background: var(--background_primary);
        h3 {
            margin: 0 0 16px;
        }
        .input {
            margin-bottom: 16px;
            border: 1px solid var(--border);
        }
        .btns {
            margin-top: 12px;
            button {
                width: 100%;
            }
        }
        .spinner {
            width: 100%;
            margin-top: 1rem;
        }
        .loginError {
            margin-top: 1rem;
            color: var(--error);
            text-align: center;
        }
    }
}
