.trip {
    width: 100%;
    height: 100%;
    position: relative;
}
.map {
    width: 100%;
    height: 100%;
    position: relative;

    .container {
        height: 100%;
        background-color: var(--background_secondary);
    }

    .controls {
        position: absolute;
        top: 24px;
        right: 24px;
        z-index: 1000;
        .box {
            background: var(--background_popup);
            border-radius: 4px;
            cursor: pointer;
            &.middle {
                margin: 16px 0;
            }
            .btn {
                padding: 4px;
                margin-bottom: 1px;
                &:not(:last-child) {
                    border-bottom: 1px solid var(--border);
                }
            }
        }
    }
}

.detail {
    position: absolute;
    z-index: 1020;
    left: 24px;
    top: 24px;
    width: 520px;
    background: var(--background_secondary);
    border: 1px solid var(--border);
    border-radius: 6px;
    padding: 16px;
    color: var(--text_primary);
    .status {
        &.active {
            svg {
                fill: var(--success);
            }
        }
        &.canceled {
            svg {
                fill: var(--error);
            }
        }
        &.completed {
            svg {
                fill: var(--order_status_created);
            }
        }
        .text {
            margin-left: 4px;
        }
    }
    .circle {
        width: 14px;
        height: 14px;
        display: inline-block;
        border: 1px solid var(--border);
        border-radius: 50%;
        margin: 0 6px;
        vertical-align: middle;
    }
    .panel {
        margin-top: 12px;
    }
    .logBtn {
        float: right;
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;
        span {
            padding: 4px;
        }
    }
}
