.table {
    width: 100%;
    border: 1px solid var(--border);
    font-size: 12px;
    thead {
        background: var(--background_secondary);
        color: var(--text_secondary);
    }
    tbody {
        background: var(--background_primary);
        color: var(--text_primary);
    }
    tr {
        border-bottom: 1px solid var(--border);
        &:hover {
            background-color: var(--hover);
        }
        td,
        th {
            box-shadow: inset -1px -1px 0px var(--border);
            padding: 8px 12px;
        }
        th {
            text-align: left;
            .sort-indicator {
                cursor: pointer;
                svg {
                    fill: var(--text_quarternary);
                }
                &.sorted {
                    svg {
                        fill: var(--primary);
                    }
                }
                &.asc {
                    transform: rotate(180deg);
                }
            }
        }
        &.selected {
            background-color: var(--background_active);
            color: var(--primary);
            box-shadow: inset 0px -3px 0px var(--primary), inset 0px 2px 0px var(--primary);
        }
    }
}
.btns {
    padding: 8px;
    text-align: center;
    .loadBtn {
        display: inline-block;
        padding: 9px 13px;
        cursor: pointer;
    }
}