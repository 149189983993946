.trips {
    width: 100%;
    .spinner {
        text-align: center;
        padding: 16px 0;
    }
    .table {
        .status {
            svg {
                margin-right: 4px;
            }
            &.active {
                svg {
                    fill: var(--success);
                }
            }
            &.canceled {
                svg {
                    fill: var(--error);
                }
            }
            &.completed {
                svg {
                    fill: var(--order_status_created);
                }
            }
        }
        .color {
            .circle {
                width: 14px;
                height: 14px;
                display: inline-block;
                border: 1px solid var(--border);
                border-radius: 50%;
                margin: 0 6px;
                vertical-align: middle;
            }
        }
        .link {
            text-decoration: underline;
        }
    }
}
